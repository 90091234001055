import { Component, Emit, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import {
  LControl,
  LControlScale,
  LControlZoom,
  LIcon,
  LLayerGroup,
  LMap,
  LMarker,
  LPopup,
  LTileLayer,
} from 'vue2-leaflet';
import LMarkerCluster from 'vue2-leaflet-markercluster';

import leafletStyle from 'leaflet/dist/leaflet.css';
import markerClusterStyle from 'leaflet.markercluster/dist/MarkerCluster.css';
import markerClusterDefaultStyle from 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import style from './Map.scss';
import { ImageWrapper, Link } from '~/components/molecules';
import { Target } from '~/utils/molecules/link';
import { MapPin, MapProps } from './types';
import { MapStyle, MapVariant } from './enums';
import { PinCategory } from '~/components/templates/mapWidget/enums';
import appEnv from '~/app/core/appEnv';
import MapCategoryPin from '~/components/templates/mapWidget/MapCategoryPin';
import { Button, Headline } from '~/components/atoms';
import { Ratios } from '~/utils/theme/ratios';
import { Align } from '~/components/atoms/headline/Headline';

import { Icon } from 'leaflet';

// @ts-ignore
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const rootClass = 'czt-map';

@Component({
  style: leafletStyle + markerClusterDefaultStyle + markerClusterStyle + style,
})
export default class Map extends VueComponent<MapProps> implements MapProps {
  @Prop({ required: true })
  public id!: string;

  @Prop({ default: false, type: Boolean })
  public detail!: boolean;

  @Prop({ required: true })
  public detailCategory!: PinCategory;

  @Prop({ default: MapStyle.BASIC })
  public mapStyle!: MapStyle;

  @Prop({ required: true })
  public center!: [number, number];

  @Prop({ default: () => [] })
  public places!: MapPin[];

  @Prop({ default: 0 })
  public syncTimeout!: number;

  @Prop({ default: MapVariant.SIMPLE })
  public variant!: MapVariant;

  @Prop({ required: true })
  public zoom!: number;

  @Ref('map')
  public map!: LMap;

  @Ref('popupLayer')
  public popupLayer!: LLayerGroup;

  protected detailCenter: [number, number] | null = null;

  protected popupData: MapPin | null = null;

  public mounted() {
    if (this.syncTimeout) {
      setTimeout(() => {
        this.map?.mapObject.invalidateSize();
      }, this.syncTimeout);
    }
    if (this.detail) {
      this.detailCenter = this.center;
    }
  }

  public render() {
    const classes = [rootClass, `${rootClass}--${this.variant}`];
    return (
      <div class={classes.join(' ')}>
        <LMap
          ref='map'
          class={`${rootClass}__wrapper`}
          id={`${this.id}--${this.variant}`}
          zoom={this.zoom}
          center={this.center}
          minZoom={0}
          maxZoom={18}
          options={
            this.variant === MapVariant.SIMPLE
              ? {
                  dragging: false,
                  zoomControl: false,
                  doubleClickZoom: false,
                  scrollWheelZoom: false,
                  touchZoom: false,
                  keyboard: false,
                }
              : {
                  zoomControl: false,
                }
          }
          {...{
            on: {
              'update:center': this.updateCenter,
              'update:zoom': this.updateZoom,
            },
          }}
        >
          {this.getTileLayer()}
          <LControl
            position={
              this.variant === MapVariant.SIMPLE ? 'topleft' : 'bottomleft'
            }
          >
            <Link url='http://mapy.cz/' target={Target.BLANK}>
              <img src='https://api.mapy.cz/img/api/logo.svg' />
            </Link>
            {this.$slots.bottomleft}
          </LControl>
          <LControlScale
            position={
              this.variant === MapVariant.SIMPLE ? 'topright' : 'bottomleft'
            }
            imperial
            metric
            maxWidth={100}
          />
          {this.variant !== MapVariant.SIMPLE && (
            <LControlZoom position='topright' />
          )}
          <LControl position='topleft'>{this.$slots.topleft}</LControl>
          <LControl position='topright'>{this.$slots.topright}</LControl>
          <LControl position='bottomright'>{this.$slots.bottomright}</LControl>
          <LMarkerCluster
            options={{
              showCoverageOnHover: false,
              disableClusteringAtZoom: 16,
            }}
          >
            {this.places
              .filter(
                (place) =>
                  (!this.detailCenter ||
                    (place.latitude !== this.detailCenter[0] &&
                      place.longitude !== this.detailCenter[1])) &&
                  place.latitude !== 0 &&
                  place.longitude !== 0
              )
              .map((place) => (
                <LMarker
                  latLng={[place.latitude, place.longitude]}
                  key={place.id}
                  name={place.title}
                  onClick={() => {
                    if (this.popupLayer.mapObject.isPopupOpen()) {
                      const popupDataId = this.popupData?.id;
                      this.popupLayer.mapObject.closePopup();
                      if (popupDataId === place.id) {
                        return;
                      }
                    }
                    this.popupData = place;
                    this.$nextTick(() => {
                      this.popupLayer.mapObject.openPopup([
                        place.latitude,
                        place.longitude,
                      ]);
                    });
                  }}
                >
                  <LIcon iconAnchor={[25, 50]} iconSize={[50, 50]}>
                    <MapCategoryPin categoryId={place.category} />
                  </LIcon>
                </LMarker>
              ))}
          </LMarkerCluster>
          {this.detailCenter && (
            <LMarker
              latLng={this.detailCenter}
              zIndexOffset={1000}
              options={{ interactive: false }}
            >
              <LIcon iconAnchor={[30, 60]} iconSize={[60, 60]}>
                <MapCategoryPin categoryId={this.detailCategory} />
              </LIcon>
            </LMarker>
          )}
          <LLayerGroup
            ref='popupLayer'
            onPopupclose={() => {
              this.popupData = null;
            }}
          >
            {this.popupData && (
              <LPopup
                options={{
                  closeOnClick: false,
                  offset: [0, -40],
                }}
                key={this.popupData.id}
              >
                <Headline level={4} align={Align.LEFT} class='mb-3'>
                  {this.popupData.title}
                </Headline>
                {this.popupData.image && (
                  <ImageWrapper
                    image={{
                      alt: this.popupData.title,
                      src: this.popupData.image,
                    }}
                    ratio={Ratios['1x1']}
                    sizes={[{ size: 300, unit: 'px' }]}
                  />
                )}
                <Button url={this.popupData.url} target={Target.BLANK}>
                  {this.$t('app.common.moreInfo')}
                </Button>
              </LPopup>
            )}
          </LLayerGroup>
        </LMap>
      </div>
    );
  }

  protected getTileLayer() {
    const attribution =
      '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>';
    const name = this.$t(`app.maps.style.${this.mapStyle}`);
    const url = `https://api.mapy.cz/v1/maptiles/${this.mapStyle}/256/{z}/{x}/{y}?apikey=${appEnv.MAPS_API_KEY}`;

    return [
      <LTileLayer attribution={attribution} name={name} url={url} />,
      this.mapStyle === MapStyle.AERIAL && (
        <LTileLayer
          url={`https://api.mapy.cz/v1/maptiles/names-overlay/256/{z}/{x}/{y}/en?apikey=${appEnv.MAPS_API_KEY}`}
          pane='overlayPane'
        />
      ),
    ];
  }

  @Emit('updateCenter')
  protected updateCenter(center: { lat: number; lng: number }) {
    return [center.lat, center.lng];
  }

  @Emit('updateZoom')
  protected updateZoom(zoom: number) {
    return zoom;
  }
}
